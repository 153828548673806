import { createTheme } from '@mui/material';

export const customTheme = createTheme({
	menu: {
		size: 99,
		margin: 16,
	},
	palette: {
		mode: 'light',
		primary: {
			main: '#E5006E',
			light: '#FDE8F2',
			light2: '#FFFCFD',
			dark: '#BC005B',
			dark2: '#990049',
			dark3: '#740038',
			keyline: '#FBCDD7',
		},
		text: {
			primary: '#000000',
			secondary: '#444444',
			tertiary: '#767676',
			disabled: '#D7D7D7',
		},
	},
	typography: {
		fontFamily: ['DM Sans', 'sans-serif'].join(','),
		h4: {
			fontSize: 37,
			fontWeight: 500,
		},
		h5: {
			fontSize: 30,
			fontWeight: 500,
		},
		h6: {},
		button: {
			fontWeight: 500,
		},
		body1: {
			fontSize: 16,
		},
		body2: {
			fontSize: 13,
		},
	},
	shape: {
		borderRadius: 5,
	},
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					fontFamily: ['DM Sans', 'sans-serif'].join(','),
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					fontSize: 16,
					fontWeight: 500,
					color: theme.palette.text.primary,
				}),
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: ({ theme }) => ({
					border: `1px solid ${theme.palette.text.primary}`,
				}),
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				listbox: {
					padding: 4,
				},
				inputRoot: ({ theme }) => ({
					padding: 10,
					color: theme.palette.text.secondary,
				}),
				option: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius,
					fontWeight: 500,

					'&[aria-selected="true"]': {
						backgroundColor: `${theme.palette.primary.main} !important`,
						color: 'white',

						'& svg': {
							color: 'white',
						},
					},
				}),
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: ({ theme }) => ({
					padding: 16,
					color: theme.palette.text.secondary,
				}),
			},
		},
		MuiChip: {
			defaultProps: {
				deleteIcon: <span className="material-symbols-outlined">close</span>,
			},
			styleOverrides: {
				deleteIcon: ({ theme }) => ({
					color: theme.palette.primary.dark,

					'&:hover': {
						color: theme.palette.primary.dark3,
					},
				}),
				root: ({ theme }) => ({
					fontWeight: 500,
					fontSize: 16,
					lineHeight: '120%',
					borderRadius: 5,
					backgroundColor: theme.palette.primary.light,
					color: theme.palette.primary.dark,
				}),
			},
		},
		MuiSlider: {
			styleOverrides: {
				root: ({ theme }) => ({
					'& .MuiSlider-valueLabel': {
						fontSize: 13,
					},
				}),
			},
		},
		MuiTooltip: {
			styleOverrides: {
				arrow: ({ theme }) => ({
					color: 'rgba(255, 255, 255, 0.98)',
				}),
				tooltip: ({ theme }) => ({
					fontSize: 13,
					borderRadius: 5,
					padding: '12px',
					background: 'rgba(255, 255, 255, 0.98)',
					color: theme.palette.text.primary,
					boxShadow: theme.shadows[7],
				}),
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {},
				sizeLarge: ({ theme, ...props }) => ({
					background: props['aria-selected'] === 'true' ? theme.palette.primary.main : 'white',
					border: `2px solid ${theme.palette.primary.main}`,
					color: props['aria-selected'] === 'true' ? 'white' : theme.palette.text.primary,
					'&:hover': {
						color: 'white',
						background: theme.palette.primary.main,
					},
					width: 59,
					height: 59,
				}),
				sizeSmall: ({ theme, ...props }) => ({
					background: props['aria-selected'] === 'true' ? theme.palette.primary.main : 'white',
					border: `2px solid ${theme.palette.primary.main}`,
					color: props['aria-selected'] === 'true' ? 'white' : theme.palette.text.primary,
					'&:hover': {
						color: 'white',
						background: theme.palette.primary.main,
					},
					width: 49,
					height: 49,
				}),
			},
		},
		MuiMenu: {
			styleOverrides: {
				list: {
					padding: 4,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: ({ theme }) => ({
					padding: 12,
					borderRadius: theme.shape.borderRadius,
					fontWeight: 500,

					':hover': {
						background: theme.palette.primary.light,
					},

					'&.Mui-selected': {
						backgroundColor: `${theme.palette.primary.main} !important`,
						color: 'white',

						'&:hover': {
							background: theme.palette.primary.main,
						},
					},
				}),
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: ({ theme }) => ({
					borderColor: theme.palette.primary.keyline,
				}),
			},
		},
		MuiButton: {
			styleOverrides: {
				outlined: ({ theme }) => ({
					border: `2px solid ${theme.palette.primary.main}`,
					color: theme.palette.text.primary,
					padding: 16,
					fontWeight: 500,
					textTransform: 'none',
					fontSize: 14,
					lineHeight: '14px',

					'&:hover': {
						border: `2px solid ${theme.palette.primary.main}`,
						color: 'white',
						background: theme.palette.primary.main,
					},
				}),
			},
		},
	},
});
