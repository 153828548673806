import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { lloydConstants } from './Constants';
import { useFilterContext, useSelectionContext, useWeightContext, useYearContext } from './context';

const useResizeObserver = (ref) => {
	const [dimensions, setDimensions] = useState(null);
	useEffect(() => {
		const observeTarget = ref.current;
		const resizeObserver = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				setDimensions(entry.contentRect);
			});
		});
		resizeObserver.observe(observeTarget);
		return () => {
			resizeObserver.unobserve(observeTarget);
		};
	}, [ref]);

	return dimensions;
};

export const divergingScale = d3.scaleDiverging().domain([20, 55, 100]).range(['#ec4f5c', '#ffce00', '#21b438']);
export const categoricalScale = d3.scaleDiverging().domain([0, 0.3, 0.75]).range(['#ec4f5c', '#ffce00', '#21b438']);

function RiskIndexViz() {
	const filters = useFilterContext();
	const weights = useWeightContext();
	const selection = useSelectionContext();

	console.debug('here', new Date().getMilliseconds());
	// console.debug(filters, weights, selection);

	const { selectedYear, yearlyData2021, yearlyData2023 } = useYearContext();
	const yearlyData = selectedYear === 2021 ? yearlyData2021 : yearlyData2023;
	// const otheryearlyData = yea

	const [filteredData2023, setFilteredData2023] = useState([]);
	const [filteredData2021, setFilteredData2021] = useState([]);

	// const [defaultOrder2021, setDefaultOrder2021] = useState(() => {
	// 	console.debug('calc usestate def oirder 2021');
	// 	return aggregateCountries(d3.groups(yearlyData2021, (d) => d.Country));
	// });
	// const [defaultOrder2023, setDefaultOrder2023] = useState(() => {
	// 	console.debug('calc usestate def oirder 2023');
	// 	return aggregateCountries(d3.groups(yearlyData2023, (d) => d.Country));
	// });

	const [defaultOrder2021, setDefaultOrder2021] = useState([]);
	const [defaultOrder2023, setDefaultOrder2023] = useState([]);

	const svgRef = useRef();
	const wrapperRef = useRef();
	const wrapDim = useResizeObserver(wrapperRef);
	const tooltip = d3.select('.ri-tooltip');
	let tooltipHidden = true;
	const svg = d3.select(svgRef.current);
	const mainGroup = svg.select('#mainGroup');

	useEffect(() => {
		console.debug('selected year dep, gonna hide tooltip stuff for 2021', selectedYear);
		const selection = d3.select('.ri-tooltip').selectAll('.ri-tooltip-old-value,.ri-tooltip-old-year');

		if (selectedYear === 2021) {
			selection.style('display', 'none');
		} else {
			selection.style('display', null);
		}
	}, [selectedYear]);

	const filterFunction = (d) => {
		if (filters.globalRegion?.length > 0) {
			if (!filters.globalRegion.map((d) => +d.id).includes(d.GlobalRegion)) return false;
		}

		if (filters.gender?.length > 0) {
			if (!filters.gender.map((d) => +d.id).includes(d.Gender)) return false;
		}

		if (filters.ageGroup?.length > 0) {
			if (filters.ageGroup[0] > d.Age || filters.ageGroup[1] < d.Age) return false;
		}

		if (filters.income?.length > 0) {
			if (!filters.income.map((d) => +d.id).includes(d.INCOME_5)) return false;
		}

		if (filters.educationLevel?.length > 0) {
			if (!filters.educationLevel.map((d) => +d.id).includes(d.Education)) return false;
		}

		if (filters.urbanicity?.length > 0) {
			if (!filters.urbanicity.map((d) => +d.id).includes(d.Urbanicity)) return false;
		}

		if (filters.householdSize?.length > 0) {
			if (!filters.householdSize.map((d) => +d.id).includes(d.HouseholdSize)) return false;
		}

		if (filters.childrenInHousehold?.length > 0) {
			if (!filters.childrenInHousehold.map((d) => +d.id).includes(d.ChildrenInHousehold)) return false;
		}

		if (filters.employmentStatus?.length > 0) {
			if (!filters.employmentStatus.map((d) => +d.id).includes(d.EMP_2010)) return false;
		}

		if (d.Household === null || d.Individual === null || d.Community === null || d.Society === null) return false;

		return true;
	};

	//filters changed hook:
	useEffect(() => {
		const filtered2023 = yearlyData2023.filter((d) => filterFunction(d));
		const filtered2021 = yearlyData2021.filter((d) => filterFunction(d));

		const countryGrouped2023 = d3.groups(filtered2023, (d) => d.Country);
		const countryGrouped2021 = d3.groups(filtered2021, (d) => d.Country);

		// setDefaultOrder2021(aggregateCountries(countryGrouped2021));
		// setDefaultOrder2023(aggregateCountries(countryGrouped2023));

		let aggregate2023 = aggregateCountries(countryGrouped2023);
		let aggregate2021 = aggregateCountries(countryGrouped2021);

		if (defaultOrder2021.length === 0) setDefaultOrder2021(aggregate2021);
		if (defaultOrder2023.length === 0) setDefaultOrder2023(aggregate2023);

		setFilteredData2023(aggregate2023);
		setFilteredData2021(aggregate2021);

		console.debug('filters & weights applied!');
	}, [filters, weights]);

	useEffect(() => {
		if (!wrapDim) return;

		const width = wrapDim.width;
		const height = wrapDim.height < 400 ? 400 : wrapDim.height;

		console.debug('im in the draw hook!', width, height);

		svg.attr('width', '100%').attr('height', '100%');

		let activeDataset = selectedYear === 2021 ? filteredData2021 : filteredData2023;

		const offset = (width * 0.75) / activeDataset.length;
		const start = width / 2 + (activeDataset.length * offset) / 2 - 50;

		const initialPosition = 250;
		const verticalStep = (height * 0.7) / activeDataset.length;

		const radiusScale = d3.scalePow().domain([0, 100]).range([50, 200]);
		const angleSlice = (Math.PI * 2) / 4; //4 componentes of the index

		const radarLine = d3
			.lineRadial()
			.curve(d3.curveBasisClosed)
			.radius((d) => radiusScale(d))
			.angle((d, i) => i * angleSlice);

		const shapesGroup = mainGroup.select('#shapes').attr('stroke', 'black').attr('stroke-width', 1);

		const countries = shapesGroup.selectAll('.country').data(activeDataset, (d) => d.country);
		const colorBy = ['Country income level', 'Resilience index'];

		// console.log(countries.enter().size(), 'countries enter size');
		// console.log(countries.exit().size(), 'countries exit size');
		// console.log(countries.size(), 'countries size');

		countries
			.enter()
			.append('path')
			.attr('class', (d) => `country ${d.country}`)
			.attr('fill', (d) => {
				if (selection.country.length === 0 || selection.country.map((d) => d.label).includes(d.country)) {
					if (selection.colorBy === colorBy[0]) {
						return categoricalScale((d.incomeLevel - 1) / 3);
					} else if (selection.colorBy === colorBy[1]) return divergingScale(d.resilience);
				} else return 'white';
			})
			.on('mouseout', handleMouseOut)
			.on('mouseover', handleMouseOver)
			.on('mousedown', handleMouseDown)
			.attr('d', radarLine([50, 50, 50, 50]))
			.attr(
				'transform',
				(_, i) => `translate(${start - i * offset}, ${initialPosition - activeDataset.length + i * verticalStep})`
			)
			.transition()
			.duration(2000)
			.delay((_, i, all) => all.length - i * 5)
			.style('opacity', 1)
			.style('pointer-events', null)
			.attr('d', (d) => radarLine([d.household, d.community, d.society, d.individual]));

		countries
			.transition()
			.delay((_, i) => i * 5)
			.duration(500)
			.attr('fill', (d) => {
				let retval = divergingScale(d.resilience); //default to resilience

				if (selection.colorBy === colorBy[0]) {
					retval = categoricalScale((d.incomeLevel - 1) / 4);
					if (d.incomeLevel === 9) retval = '#D7D7D7';
				}

				if (
					selection.incomeLevel.length > 0 &&
					selection.incomeLevel.findIndex((item) => +item.id === +d.incomeLevel) === -1
				) {
					retval = 'white';
				}

				if (selection.country.length > 0 && selection.country.findIndex((item) => item.label === d.country) === -1) {
					retval = 'white';
				}

				return retval;
			})
			.attr('d', (d) => {
				// console.debug("doing this for ", d.country);
				const metrics = [d.household, d.community, d.society, d.individual];

				if (metrics.includes(undefined)) {
					return null;
				}

				return radarLine(metrics);
			})
			.attr('transform', (d, i) => {
				let hOffset = 0;

				if (selection.country.length > 0 && selection.country.findIndex((item) => item.label === d.country) > -1) {
					hOffset = 50;
				}

				if (
					selection.incomeLevel.length > 0 &&
					selection.incomeLevel.findIndex((item) => +item.id === +d.incomeLevel) === -1
				) {
					hOffset = 0;
				}

				return `translate(${start - i * offset}, ${
					initialPosition - activeDataset.length + i * verticalStep - hOffset
				})`;
			})
			.style('opacity', 1)
			.style('pointer-events', null)
			.each(function (d) {
				d3.select(this).raise();
			});

		countries
			.exit()
			.transition()
			.duration(500)
			.attr('fill', 'white')
			.style('opacity', 0)
			.style('pointer-events', 'none');
	}, [wrapDim, selectedYear, filteredData2021, filteredData2023, selection]);

	function handleMouseOut(event, d) {
		d3.select(this).classed('highlight', false);

		tooltipHidden = true;
		tooltip.style('visibility', tooltipHidden ? 'hidden' : 'visible');
	}

	function handleMouseDown(event, d) {
		const country = lloydConstants.countriesProps[d.country];

		if (country) {
			const filterObject = {
				id: d.country,
				label: d.country,
				group: country.region,
			};

			selection.setCountry((countries) => {
				//check if the country is already in the filter and remove it
				const index = countries.findIndex((item) => item.id === filterObject.id);

				if (index > -1) {
					return countries.filter((item) => item.id !== filterObject.id);
				} else {
					return [...countries, filterObject];
				}
			});
		}
	}

	function handleMouseOver(event, d) {
		d3.select(this).classed('highlight', true);

		tooltipHidden = !tooltipHidden;

		tooltip.select('.ri-tooltip-title').text(d.country);
		// tooltip.select('.ri-tooltip-row.income-group').select('.ri-tooltip-value').text(d.incomeLevel);
		//convert using the map to income level names
		tooltip
			.select('.ri-tooltip-row.income-group')
			.select('.ri-tooltip-value')
			.text(lloydConstants.incomeLevels[d.incomeLevel]);
		tooltip.select('.ri-tooltip-row.global-region').select('.ri-tooltip-value').text(d.region);

		tooltip.select('.ri-tooltip-row.household').select('.ri-tooltip-value').text(d3.format('.0f')(d.household));
		tooltip
			.select('.ri-tooltip-row.household')
			.select('.ri-tooltip-color-value')
			.style('fill', divergingScale(d.household));
		tooltip.select('.ri-tooltip-row.community').select('.ri-tooltip-value').text(d3.format('.0f')(d.community));
		tooltip
			.select('.ri-tooltip-row.community')
			.select('.ri-tooltip-color-value')
			.style('fill', divergingScale(d.community));
		tooltip.select('.ri-tooltip-row.society').select('.ri-tooltip-value').text(d3.format('.0f')(d.society));
		tooltip
			.select('.ri-tooltip-row.society')
			.select('.ri-tooltip-color-value')
			.style('fill', divergingScale(d.society));
		tooltip.select('.ri-tooltip-row.individual').select('.ri-tooltip-value').text(d3.format('.0f')(d.individual));
		tooltip
			.select('.ri-tooltip-row.individual')
			.select('.ri-tooltip-color-value')
			.style('fill', divergingScale(d.individual));

		tooltip
			.select('.ri-tooltip-row.resilience')
			.select('.ri-tooltip-value')
			.text(`${d3.format('.0f')(d.resilience)}`);

		//find the country in the 2021 dataset
		if (selectedYear === 2023) {
			const country2021 = defaultOrder2021.find((item) => item.country === d.country);

			if (!country2021) {
				tooltip.select('.ri-tooltip-old-year').text(`No data for 2021`);
			} else {
				tooltip.select('.ri-tooltip-old-year').text(`Compared with data from 2021`);
			}

			tooltip
				.select('.ri-tooltip-row.resilience')
				.select('.ri-tooltip-old-value')
				.text(country2021 ? `${d3.format('.0f')(country2021.resilience)}` : 'N/A');

			//also add the old-value for the 4 metrics (household, community, society, individual)
			tooltip
				.select('.ri-tooltip-row.household')
				.select('.ri-tooltip-old-value')
				.text(country2021 ? `${d3.format('.0f')(country2021.household)}` : 'N/A');

			tooltip
				.select('.ri-tooltip-row.community')
				.select('.ri-tooltip-old-value')
				.text(country2021 ? `${d3.format('.0f')(country2021.community)}` : 'N/A');

			tooltip
				.select('.ri-tooltip-row.society')
				.select('.ri-tooltip-old-value')
				.text(country2021 ? `${d3.format('.0f')(country2021.society)}` : 'N/A');

			tooltip
				.select('.ri-tooltip-row.individual')
				.select('.ri-tooltip-old-value')
				.text(country2021 ? `${d3.format('.0f')(country2021.individual)}` : 'N/A');
		}

		tooltip.select('.ri-tooltip-row.total').select('.ri-tooltip-value').text(d3.format(',')(d.total));
		tooltip.select('.ri-tooltip-row.filtered').select('.ri-tooltip-value').text(d3.format(',')(d.count));

		const original2021 = defaultOrder2021.findIndex((item) => item.country === d.country);
		const original2023 = defaultOrder2023.findIndex((item) => item.country === d.country);

		let origPosition = selectedYear === 2021 ? original2021 : original2023;
		let length = selectedYear === 2021 ? defaultOrder2021.length : defaultOrder2023.length;

		let previousPosition = selectedYear === 2021 ? null : original2021;

		tooltip
			.select('.ri-tooltip-row.original-position')
			.select('.ri-tooltip-value')
			.text(`${origPosition + 1} / ${length}`);

		if (selectedYear === 2023) {
			tooltip
				.select('.ri-tooltip-row.original-position')
				.select('.ri-tooltip-old-value')
				.attr('display', null)
				.text(previousPosition >= 0 ? `${previousPosition + 1} / ${defaultOrder2021.length}` : 'N/A');
		}

		setFilteredData2023((data) => {
			const filtered = data.findIndex((item) => item.country === d.country);

			tooltip
				.select('.ri-tooltip-row.filtered-position')
				.select('.ri-tooltip-value')
				.text(`${filtered + 1} / ${data.length}`);

			return data;
		});

		setFilteredData2021((data) => {
			// const filtered = data.findIndex((item) => item.country === d.country);

			if (selectedYear === 2023) {
				const original2021 = defaultOrder2021.findIndex((item) => item.country === d.country);
				tooltip
					.select('.ri-tooltip-row.filtered-position')
					.select('.ri-tooltip-old-value')
					// .attr('display', null)
					.text(original2021 >= 0 ? `${original2021 + 1} / ${data.length}` : 'N/A');
			}

			return data;
		});

		tooltip
			.select('.ri-tooltip-row.gender')
			.select('.ri-tooltip-value')
			.text(`${d3.format('.0%')(1 - d.mfRatio)} / ${d3.format('.0%')(d.mfRatio)}`);

		tooltip.style('visibility', tooltipHidden ? 'hidden' : 'visible');
	}

	function aggregateCountries(countryGrouped) {
		console.debug('aggregating countries', countryGrouped.length);
		const total = weights.household + weights.community + weights.society + weights.individual;

		let processed = [];
		countryGrouped.forEach((d) => {
			const householdMean = d3.mean(d[1], (record) => record.Household);
			const communityMean = d3.mean(d[1], (record) => record.Community);
			const societyMean = d3.mean(d[1], (record) => record.Society);
			const individualMean = d3.mean(d[1], (record) => record.Individual);
			const weightMean = d3.mean(d[1], (record) => record.weight);

			const item = {
				country: d[0],
				incomeLevel: d[1][0].CountryIncomeLevel2021,
				region: d[1][0].GlobalRegion,
				household: householdMean / weightMean,
				community: communityMean / weightMean,
				society: societyMean / weightMean,
				individual: individualMean / weightMean,
				resilience:
					((householdMean / weightMean) * weights.household) / total +
					((communityMean / weightMean) * weights.community) / total +
					((societyMean / weightMean) * weights.society) / total +
					((individualMean / weightMean) * weights.individual) / total,
				count: d[1].length,
				total: yearlyData.filter((record) => record.Country === d[0]).length,
				mfRatio: d[1].filter((record) => record.Gender === 1).length / d[1].length,
			};

			// check if all 4 metrics are defined, and the resilience index is not NaN
			if (
				!isNaN(item.resilience) &&
				item.resilience > 0 &&
				!isNaN(item.household) &&
				item.household > 0 &&
				!isNaN(item.community) &&
				item.community > 0 &&
				!isNaN(item.society) &&
				item.society > 0 &&
				!isNaN(item.individual) &&
				item.individual > 0
			) {
				processed.push(item);
			}
		});

		processed = processed.sort((a, b) => b.resilience - a.resilience);
		return processed;
	}

	return (
		<div ref={wrapperRef} className="viz-wrapper">
			<svg ref={svgRef}>
				<g id="mainGroup">
					<g id="shapes" />
					<g id="example" />
				</g>
			</svg>
		</div>
	);
}

export default RiskIndexViz;
