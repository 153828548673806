import { Stack, Typography } from '@mui/material';
import { useSelectionContext } from '../../context';
import { divergingScale } from '../../RiskIndexViz';

const ResilienceIndexLegend = () => {
	const { colorBy } = useSelectionContext();

	const renderLegendItem = (value, label) => {
		const colorSize = 16;
		const color = divergingScale(value);

		return (
			<Stack direction={'row'} alignItems={'center'} spacing={1}>
				<svg width={colorSize + 2} height={colorSize + 2} fill={color}>
					<circle cx={colorSize / 2 + 1} cy={colorSize / 2 + 1} r={colorSize / 2} stroke={'black'} />
				</svg>
				<Typography variant={'body2'} fontWeight={500} sx={{ marginLeft: '4px !important', paddingTop: 0 }}>
					{label}
				</Typography>
			</Stack>
		);
	};

	return (
		<Stack
			spacing={'60px'}
			sx={{
				position: 'absolute',
				bottom: 20,
				left: 20,
				display: colorBy !== 'Resilience index' ? 'none' : undefined,
			}}
			direction={'row'}
			alignItems={'center'}
		>
			<Typography fontWeight={500}>Resilience index levels</Typography>
			<Stack spacing={3} alignItems={'center'} direction={'row'}>
				{renderLegendItem(100, '100')}
				{renderLegendItem(80, '80')}
				{renderLegendItem(60, '60')}
				{renderLegendItem(40, '40')}
				{renderLegendItem(20, '20')}
			</Stack>
		</Stack>
	);
};

export default ResilienceIndexLegend;
