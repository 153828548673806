import { ToggleButton, useTheme } from '@mui/material';
import { StyledToggleButtonGroup } from './controlViewSwitch.styles';
import * as React from 'react';
import { useYearContext } from '../../context';

const YearToggle = () => {
	const theme = useTheme();
	const { selectedYear, setSelectedYear } = useYearContext();

	const handleChange = (event, value) => {
		if (value) {
			setSelectedYear(value);
		}
	};

	return (
		<StyledToggleButtonGroup
			color="primary"
			value={selectedYear}
			exclusive
			onChange={handleChange}
			sx={{
				position: 'absolute',
				top: `${20 - theme.menu.size}px`,
				left: 20,
			}}
		>
			<ToggleButton value={2023}>2023</ToggleButton>
			<ToggleButton value={2021}>2021</ToggleButton>
		</StyledToggleButtonGroup>
	);
};

export default YearToggle;
