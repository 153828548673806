import { createContext, useContext, useState } from 'react';

const defaultValues = {
	selectedYear: 2023,
	setSelectedYear: () => {},	
	setYearlyData2021: () => {},
	setYearlyData2023: () => {},
};

const YearContext = createContext(defaultValues);

export const YearProvider = ({ children }) => {
	const [selectedYear, setSelectedYear] = useState(defaultValues.selectedYear);
	const [yearlyData2021, setYearlyData2021] = useState([]);
	const [yearlyData2023, setYearlyData2023] = useState([]);

	return (
		<YearContext.Provider
			value={{
				selectedYear,
				setSelectedYear,
				yearlyData2021,
				setYearlyData2021,
				yearlyData2023,
				setYearlyData2023
			}}
		>
			{children}
		</YearContext.Provider>
	);
};

export const useYearContext = () => useContext(YearContext);
