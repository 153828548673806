import { Box, InputLabel, Slider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

const RangeWithChips = ({ label, value, setValue, chips, ...props }) => {
	const [intermediateValue, setIntermediateValue] = useState(value);

	useEffect(() => {
		setIntermediateValue(value);
	}, [value]);

	const handleChange = (event, newValue) => {
		setIntermediateValue(newValue);
	};

	const handleChangeCommitted = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Stack spacing={'5px'}>
			<InputLabel>{label}</InputLabel>
			<Box pr={2} pl={1}>
				<Slider
					size={'medium'}
					orientation={'horizontal'}
					value={intermediateValue}
					onChangeCommitted={handleChangeCommitted}
					onChange={handleChange}
					valueLabelDisplay={'auto'}
					disableSwap
					step={1}
					marks={[0, 20, 40, 60, 80, 100].map((num, index, array) => ({
						value: num,
						label: `${num.toString()}`,
					}))}
					min={15}
					max={100}
					{...props}
				/>
			</Box>
		</Stack>
	);
};

export default RangeWithChips;
