import { Box, Typography } from '@mui/material';
import FiltersView from '../FiltersView';
import WeightsView from '../WeightsView';
import SelectionView from '../SelectionView';
import { useMenuContext } from '../../context';
import { controlViews } from '../../context/MenuContext';

const ControlsView = () => {
	const { selectedMenuItem } = useMenuContext();

	const renderControlView = () => {
		switch (selectedMenuItem) {
			case controlViews[1]:
				return <FiltersView />;
			case controlViews[2]:
				return <WeightsView />;
			case controlViews[0]:
				return <SelectionView />;
			default:
				return <></>;
		}
	};

	return (
		<Box
			pb={'20px'}
			pr={'20px'}
			pl={'20px'}
			sx={{
				overflowY: 'auto',
				overflowX: 'hidden',
				height: '100%',
				justifyContent: 'space-between',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			{renderControlView()}
			<Typography variant={'body2'} color={'text.tertiary'} mt={'20px'}>
				Data visualization by Velimir Gasparovic
			</Typography>
		</Box>
	);
};

export default ControlsView;
