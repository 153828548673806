import { createContext, useContext, useMemo, useState } from 'react';

export const defaultWeightValues = {
	community: 50,
	setCommunity: () => {},
	household: 50,
	setHousehold: () => {},
	society: 50,
	setSociety: () => {},
	individual: 50,
	setIndividual: () => {},
	isEmpty: true,
};

const WeightContext = createContext(defaultWeightValues);

export const WeightProvider = ({ children }) => {
	const [community, setCommunity] = useState(defaultWeightValues.community);
	const [household, setHousehold] = useState(defaultWeightValues.household);
	const [society, setSociety] = useState(defaultWeightValues.society);
	const [individual, setIndividual] = useState(defaultWeightValues.individual);

	const isEmpty = useMemo(() => {
		let isDefault = true;

		for (const value of [community, household, society, individual]) {
			if (value !== defaultWeightValues.community) {
				isDefault = false;
				break;
			}
		}

		return isDefault;
	}, [community, household, society, individual]);

	return (
		<WeightContext.Provider
			value={{
				community,
				setCommunity,
				household,
				setHousehold,
				society,
				setSociety,
				individual,
				setIndividual,
				isEmpty,
			}}
		>
			{children}
		</WeightContext.Provider>
	);
};

export const useWeightContext = () => useContext(WeightContext);
