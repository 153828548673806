import styled from '@emotion/styled';
import { ToggleButtonGroup } from '@mui/material';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	'& .MuiToggleButtonGroup-grouped': {
		transition: '0.3s all ease',
		border: `2px solid ${theme.palette.primary.main}`,
		color: theme.palette.text.primary,
		height: 59,
		width: 59,

		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		'&.Mui-selected': {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,

			'&:hover': {
				backgroundColor: theme.palette.primary.main,
			},
		},
	},
}));
