import {
	Autocomplete as MUIAutocomplete,
	Box,
	Checkbox,
	createFilterOptions,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from '@mui/material';

const Autocomplete = ({ label, isGroup = false, setValue, options, multiple = true, ...props }) => {
	const checkGroup = (group) => {
		const groupLength = options.filter((c) => c.group === group).length;
		const selectedGroupLength = props.value.filter((c) => c.group === group).length;
		return groupLength === selectedGroupLength;
	};

	const isGroupIndeterminate = (group) => {
		const groupLength = options.filter((c) => c.group === group).length;
		const selectedGroupLength = props.value.filter((c) => c.group === group).length;
		return selectedGroupLength > 0 && selectedGroupLength < groupLength;
	};

	const selectGroup = (group) => {
		const groupedOptions = options.filter((c) => c.group === group);
		const selectedGroupOptions = props.value.filter((c) => c.group === group);

		if (selectedGroupOptions.length > 0) {
			setValue((prevState) => [...prevState.filter((c) => c.group !== group)]);
		} else {
			setValue((prevState) => [...prevState, ...groupedOptions]);
		}
	};

	const filterOptions = createFilterOptions({
		stringify: (option) => option.label + option.group,
	});

	return (
		<Stack spacing={1}>
			<InputLabel>{label}</InputLabel>
			<MUIAutocomplete
				multiple={multiple}
				limitTags={2}
				size={'medium'}
				getOptionLabel={(option) => option.label}
				disableCloseOnSelect={multiple}
				renderInput={(params) => <TextField {...params} />}
				renderOption={
					multiple
						? (props, option, { selected }) => (
								<li {...props}>
									<Checkbox style={{ marginRight: 8, marginLeft: isGroup ? 16 : 0 }} checked={selected} />
									{option.label}
								</li>
						  )
						: undefined
				}
				renderGroup={(params) => (
					<Box key={params.group}>
						<Checkbox
							checked={checkGroup(params.group)}
							indeterminate={isGroupIndeterminate(params.group)}
							onChange={() => selectGroup(params.group)}
							style={{ marginRight: 8, marginLeft: 8 }}
						/>
						<Typography fontWeight={500} color={'text.secondary'} component={'span'}>
							{params.group}
						</Typography>
						{params.children}
					</Box>
				)}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(event, newValue) => {
					setValue(newValue);
				}}
				groupBy={isGroup ? (option) => option.group : undefined}
				options={
					isGroup
						? options.sort((a, b) => -b.label.localeCompare(a.label)).sort((a, b) => -b.group.localeCompare(a.group))
						: options
				}
				filterOptions={isGroup ? filterOptions : undefined}
				{...props}
			/>
		</Stack>
	);
};

export default Autocomplete;
