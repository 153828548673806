export const lloydConstants = {
	countryCodeToName: {
		1: 'United States',
		2: 'Egypt',
		3: 'Morocco',
		4: 'Lebanon',
		5: 'Saudi Arabia',
		6: 'Jordan',
		8: 'Turkey',
		9: 'Pakistan',
		10: 'Indonesia',
		11: 'Bangladesh',
		12: 'United Kingdom',
		13: 'France',
		14: 'Germany',
		15: 'Netherlands',
		16: 'Belgium',
		17: 'Spain',
		18: 'Italy',
		19: 'Poland',
		20: 'Hungary',
		21: 'Czech Republic',
		22: 'Romania',
		23: 'Sweden',
		24: 'Greece',
		25: 'Denmark',
		26: 'Iran',
		27: 'Hong Kong',
		28: 'Singapore',
		29: 'Japan',
		30: 'China',
		31: 'India',
		32: 'Venezuela',
		33: 'Brazil',
		34: 'Mexico',
		35: 'Nigeria',
		36: 'Kenya',
		37: 'Tanzania',
		38: 'Israel',
		39: 'Palestinian Territories',
		40: 'Ghana',
		41: 'Uganda',
		42: 'Benin',
		43: 'Madagascar',
		44: 'Malawi',
		45: 'South Africa',
		46: 'Canada',
		47: 'Australia',
		48: 'Philippines',
		49: 'Sri Lanka',
		50: 'Vietnam',
		51: 'Thailand',
		52: 'Cambodia',
		53: 'Laos',
		54: 'Myanmar',
		55: 'New Zealand',
		57: 'Botswana',
		60: 'Ethiopia',
		61: 'Mali',
		62: 'Mauritania',
		63: 'Mozambique',
		64: 'Niger',
		65: 'Rwanda',
		66: 'Senegal',
		67: 'Zambia',
		68: 'South Korea',
		69: 'Taiwan',
		70: 'Afghanistan',
		71: 'Belarus',
		72: 'Georgia',
		73: 'Kazakhstan',
		74: 'Kyrgyzstan',
		75: 'Moldova',
		76: 'Russia',
		77: 'Ukraine',
		78: 'Burkina Faso',
		79: 'Cameroon',
		80: 'Sierra Leone',
		81: 'Zimbabwe',
		82: 'Costa Rica',
		83: 'Albania',
		84: 'Algeria',
		87: 'Argentina',
		88: 'Armenia',
		89: 'Austria',
		90: 'Azerbaijan',
		92: 'Bahrain',
		96: 'Bolivia',
		97: 'Bosnia and Herzegovina',
		99: 'Bulgaria',
		103: 'Chad',
		104: 'Chile',
		105: 'Colombia',
		108: 'Congo Brazzaville',
		109: 'Croatia',
		111: 'Cyprus',
		114: 'Dominican Republic',
		115: 'Ecuador',
		116: 'El Salvador',
		119: 'Estonia',
		121: 'Finland',
		122: 'Gabon',
		124: 'Guatemala',
		125: 'Guinea',
		129: 'Honduras',
		130: 'Iceland',
		131: 'Iraq',
		132: 'Ireland',
		134: 'Ivory Coast',
		135: 'Jamaica',
		137: 'Kuwait',
		138: 'Latvia',
		139: 'Lesotho',
		140: 'Liberia',
		141: 'Libya',
		143: 'Lithuania',
		144: 'Luxembourg',
		145: 'North Macedonia',
		146: 'Malaysia',
		148: 'Malta',
		150: 'Mauritius',
		153: 'Mongolia',
		154: 'Montenegro',
		155: 'Namibia',
		157: 'Nepal',
		158: 'Nicaragua',
		160: 'Norway',
		163: 'Panama',
		164: 'Paraguay',
		165: 'Peru',
		166: 'Portugal',
		173: 'Serbia',
		175: 'Slovakia',
		176: 'Slovenia',
		183: 'Eswatini',
		184: 'Switzerland',
		185: 'Tajikistan',
		186: 'The Gambia',
		187: 'Togo',
		190: 'Tunisia',
		191: 'Turkmenistan',
		193: 'United Arab Emirates',
		194: 'Uruguay',
		195: 'Uzbekistan',
		197: 'Yemen',
		198: 'Kosovo',
		199: 'Comoros',
		200: 'Congo (Kinshasa)',
		201: 'Somalia',
	},
	countriesProps: {
		'United States': { region: 'Northern America', country_income: 'High income' },
		Egypt: { region: 'Northern Africa', country_income: 'Lower middle income' },
		Morocco: { region: 'Northern Africa', country_income: 'Lower middle income' },
		Lebanon: { region: 'Middle East', country_income: 'Lower middle income' },
		'Saudi Arabia': { region: 'Middle East', country_income: 'High income' },
		Jordan: { region: 'Middle East', country_income: 'Lower middle income' },
		Turkey: { region: 'Middle East', country_income: 'Upper middle income' },
		Pakistan: { region: 'Southern Asia', country_income: 'Lower middle income' },
		Indonesia: { region: 'Southeastern Asia', country_income: 'Upper middle income' },
		Bangladesh: { region: 'Southern Asia', country_income: 'Lower middle income' },
		'United Kingdom': { region: 'Northern/Western Europe', country_income: 'High income' },
		France: { region: 'Northern/Western Europe', country_income: 'High income' },
		Germany: { region: 'Northern/Western Europe', country_income: 'High income' },
		Netherlands: { region: 'Northern/Western Europe', country_income: 'High income' },
		Belgium: { region: 'Northern/Western Europe', country_income: 'High income' },
		Spain: { region: 'Southern Europe', country_income: 'High income' },
		Italy: { region: 'Southern Europe', country_income: 'High income' },
		Poland: { region: 'Eastern Europe', country_income: 'High income' },
		Hungary: { region: 'Eastern Europe', country_income: 'High income' },
		'Czech Republic': { region: 'Eastern Europe', country_income: 'High income' },
		Romania: { region: 'Eastern Europe', country_income: 'High income' },
		Sweden: { region: 'Northern/Western Europe', country_income: 'High income' },
		Greece: { region: 'Southern Europe', country_income: 'High income' },
		Denmark: { region: 'Northern/Western Europe', country_income: 'High income' },
		Iran: { region: 'Southern Asia', country_income: 'Lower middle income' },
		'Hong Kong': { region: 'Eastern Asia', country_income: 'High income' },
		Singapore: { region: 'Southeastern Asia', country_income: 'High income' },
		Japan: { region: 'Eastern Asia', country_income: 'High income' },
		China: { region: 'Eastern Asia', country_income: 'Upper middle income' },
		India: { region: 'Southern Asia', country_income: 'Lower middle income' },
		Venezuela: { region: 'Latin America & the Caribbean', country_income: 'Not classified' },
		Brazil: { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Mexico: { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Nigeria: { region: 'Central/Western Africa', country_income: 'Lower middle income' },
		Kenya: { region: 'Eastern Africa', country_income: 'Lower middle income' },
		Tanzania: { region: 'Eastern Africa', country_income: 'Lower middle income' },
		Israel: { region: 'Middle East', country_income: 'High income' },
		'Palestinian Territories': { region: 'Middle East', country_income: 'Upper middle income' },
		Ghana: { region: 'Central/Western Africa', country_income: 'Lower middle income' },
		Uganda: { region: 'Eastern Africa', country_income: 'Low income' },
		Benin: { region: 'Central/Western Africa', country_income: 'Lower middle income' },
		Madagascar: { region: 'Eastern Africa', country_income: 'Low income' },
		Malawi: { region: 'Eastern Africa', country_income: 'Low income' },
		'South Africa': { region: 'Southern Africa', country_income: 'Upper middle income' },
		Canada: { region: 'Northern America', country_income: 'High income' },
		Australia: { region: 'Australia & New Zealand', country_income: 'High income' },
		Philippines: { region: 'Southeastern Asia', country_income: 'Lower middle income' },
		'Sri Lanka': { region: 'Southern Asia', country_income: 'Lower middle income' },
		Vietnam: { region: 'Southeastern Asia', country_income: 'Lower middle income' },
		Thailand: { region: 'Southeastern Asia', country_income: 'Upper middle income' },
		Cambodia: { region: 'Southeastern Asia', country_income: 'Lower middle income' },
		Laos: { region: 'Southeastern Asia', country_income: 'Lower middle income' },
		Myanmar: { region: 'Southeastern Asia', country_income: 'Lower middle income' },
		'New Zealand': { region: 'Australia & New Zealand', country_income: 'High income' },
		Botswana: { region: 'Southern Africa', country_income: 'Upper middle income' },
		Ethiopia: { region: 'Eastern Africa', country_income: 'Low income' },
		Mali: { region: 'Central/Western Africa', country_income: 'Low income' },
		Mauritania: { region: 'Central/Western Africa', country_income: 'Lower middle income' },
		Mozambique: { region: 'Eastern Africa', country_income: 'Low income' },
		Niger: { region: 'Central/Western Africa', country_income: 'Low income' },
		Senegal: { region: 'Central/Western Africa', country_income: 'Lower middle income' },
		Zambia: { region: 'Eastern Africa', country_income: 'Lower middle income' },
		'South Korea': { region: 'Eastern Asia', country_income: 'High income' },
		Taiwan: { region: 'Eastern Asia', country_income: 'High income' },
		Afghanistan: { region: 'Southern Asia', country_income: 'Low income' },
		Georgia: { region: 'Central Asia', country_income: 'Upper middle income' },
		Kazakhstan: { region: 'Central Asia', country_income: 'Upper middle income' },
		Kyrgyzstan: { region: 'Central Asia', country_income: 'Lower middle income' },
		Moldova: { region: 'Eastern Europe', country_income: 'Upper middle income' },
		Russia: { region: 'Eastern Europe', country_income: 'Upper middle income' },
		Ukraine: { region: 'Eastern Europe', country_income: 'Lower middle income' },
		'Burkina Faso': { region: 'Central/Western Africa', country_income: 'Low income' },
		Cameroon: { region: 'Central/Western Africa', country_income: 'Lower middle income' },
		'Sierra Leone': { region: 'Central/Western Africa', country_income: 'Low income' },
		Zimbabwe: { region: 'Eastern Africa', country_income: 'Lower middle income' },
		'Costa Rica': { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Albania: { region: 'Southern Europe', country_income: 'Upper middle income' },
		Algeria: { region: 'Northern Africa', country_income: 'Lower middle income' },
		Argentina: { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Armenia: { region: 'Central Asia', country_income: 'Upper middle income' },
		Austria: { region: 'Northern/Western Europe', country_income: 'High income' },
		Azerbaijan: { region: 'Central Asia', country_income: 'Upper middle income' },
		Bahrain: { region: 'Middle East', country_income: 'High income' },
		Bolivia: { region: 'Latin America & the Caribbean', country_income: 'Lower middle income' },
		'Bosnia and Herzegovina': { region: 'Southern Europe', country_income: 'Upper middle income' },
		Bulgaria: { region: 'Eastern Europe', country_income: 'Upper middle income' },
		Chad: { region: 'Central/Western Africa', country_income: 'Low income' },
		Chile: { region: 'Latin America & the Caribbean', country_income: 'High income' },
		Colombia: { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Comoros: { region: 'Eastern Africa', country_income: 'Lower middle income' },
		'Congo (Kinshasa)': { region: 'Central/Western Africa', country_income: 'Low income' },
		'Congo Brazzaville': { region: 'Central/Western Africa', country_income: 'Lower middle income' },
		Croatia: { region: 'Southern Europe', country_income: 'High income' },
		Cyprus: { region: 'Southern Europe', country_income: 'High income' },
		'Dominican Republic': { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Ecuador: { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		'El Salvador': { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Estonia: { region: 'Northern/Western Europe', country_income: 'High income' },
		Finland: { region: 'Northern/Western Europe', country_income: 'High income' },
		Gabon: { region: 'Central/Western Africa', country_income: 'Upper middle income' },
		Guatemala: { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Guinea: { region: 'Central/Western Africa', country_income: 'Lower middle income' },
		Honduras: { region: 'Latin America & the Caribbean', country_income: 'Lower middle income' },
		Iceland: { region: 'Northern/Western Europe', country_income: 'High income' },
		Iraq: { region: 'Middle East', country_income: 'Upper middle income' },
		Ireland: { region: 'Northern/Western Europe', country_income: 'High income' },
		'Ivory Coast': { region: 'Central/Western Africa', country_income: 'Lower middle income' },
		Kuwait: { region: 'Middle East', country_income: 'High income' },
		Latvia: { region: 'Northern/Western Europe', country_income: 'High income' },
		Liberia: { region: 'Central/Western Africa', country_income: 'Low income' },
		Libya: { region: 'Northern Africa', country_income: 'Upper middle income' },
		Lithuania: { region: 'Northern/Western Europe', country_income: 'High income' },
		Luxembourg: { region: 'Northern/Western Europe', country_income: 'High income' },
		'North Macedonia': { region: 'Southern Europe', country_income: 'Upper middle income' },
		Malaysia: { region: 'Southeastern Asia', country_income: 'Upper middle income' },
		Malta: { region: 'Southern Europe', country_income: 'High income' },
		Mauritius: { region: 'Eastern Africa', country_income: 'Upper middle income' },
		Mongolia: { region: 'Eastern Asia', country_income: 'Lower middle income' },
		Montenegro: { region: 'Southern Europe', country_income: 'Upper middle income' },
		Namibia: { region: 'Southern Africa', country_income: 'Upper middle income' },
		Nepal: { region: 'Southern Asia', country_income: 'Lower middle income' },
		Nicaragua: { region: 'Latin America & the Caribbean', country_income: 'Lower middle income' },
		Norway: { region: 'Northern/Western Europe', country_income: 'High income' },
		Panama: { region: 'Latin America & the Caribbean', country_income: 'High income' },
		Paraguay: { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Peru: { region: 'Latin America & the Caribbean', country_income: 'Upper middle income' },
		Portugal: { region: 'Southern Europe', country_income: 'High income' },
		Serbia: { region: 'Southern Europe', country_income: 'Upper middle income' },
		Slovakia: { region: 'Eastern Europe', country_income: 'High income' },
		Slovenia: { region: 'Southern Europe', country_income: 'High income' },
		Somalia: { region: 'Eastern Africa', country_income: 'Low income' },
		Eswatini: { region: 'Southern Africa', country_income: 'Lower middle income' },
		Switzerland: { region: 'Northern/Western Europe', country_income: 'High income' },
		Tajikistan: { region: 'Central Asia', country_income: 'Lower middle income' },
		'The Gambia': { region: 'Central/Western Africa', country_income: 'Low income' },
		Togo: { region: 'Central/Western Africa', country_income: 'Low income' },
		Tunisia: { region: 'Northern Africa', country_income: 'Lower middle income' },
		'United Arab Emirates': { region: 'Middle East', country_income: 'High income' },
		Uruguay: { region: 'Latin America & the Caribbean', country_income: 'High income' },
		Uzbekistan: { region: 'Central Asia', country_income: 'Lower middle income' },
		Yemen: { region: 'Middle East', country_income: 'Low income' },
		Kosovo: { region: 'Eastern Europe', country_income: 'Upper middle income' },
		Jamaica: {
			region: 'Latin America & the Caribbean',
			country_income: 'Upper-middle income',
		},
	},
	globalRegions: {
		1: 'Eastern Africa',
		2: 'Central/Western Africa',
		3: 'Northern Africa',
		4: 'Southern Africa',
		5: 'Latin America & the Caribbean',
		6: 'Northern America',
		7: 'Central Asia',
		8: 'Eastern Asia',
		9: 'Southeastern Asia',
		10: 'Southern Asia',
		11: 'Middle East',
		12: 'Eastern Europe',
		13: 'Northern/Western Europe',
		14: 'Southern Europe',
		15: 'Australia & New Zealand',
	},
	incomeLevels: {
		1: 'Low income',
		2: 'Lower middle income',
		3: 'Upper middle income',
		4: 'High income',
		9: 'Not classified',
	},
	reverseIncomeLevels: {
		'Low income': 1,
		'Lower middle income': 2,
		'Upper middle income': 3,
		'High income': 4,
		'Not classified': 9,
	},
	ageGroups: {
		1: {
			label: '15-29',
			value: [15, 29],
		},
		2: {
			label: '30-49',
			value: [30, 49],
		},
		3: {
			label: '50-64',
			value: [50, 64],
		},
		4: {
			label: '65+',
			value: [65, 100],
		},
	},
	genders: {
		1: 'Male',
		2: 'Female',
	},
	disasterExperience: {
		1: 'Yes',
		2: 'No',
		98: 'DK/Refused',
		99: 'DK/Refused',
	},
	educationLevels: {
		1: 'Primary (0-8 years)',
		2: 'Secondary (9-15 years)',
		3: 'Tertiary (16 years or more)',
		9: 'DK/Refused',
	},
	incomeQuintiles: {
		1: 'Poorest 20%',
		2: 'Second 20%',
		3: 'Middle 20%',
		4: 'Fourth 20%',
		5: 'Richest 20%',
	},
	employmentStatus: {
		1: 'Employed full time for an employer',
		2: 'Employed full time for self',
		3: 'Employed part time do not want full time',
		4: 'Unemployed',
		5: 'Employed part time want full time',
		6: 'Out of workforce',
	},
	urbanicity: {
		1: 'Rural areas',
		2: 'Towns and semi-dense areas',
		3: 'Cities',
		9: 'DK/Refused',
	},
	householdSize: {
		1: '1-2 people',
		2: '3-4 people',
		3: '5-9 people',
		4: '10 or more',
	},
	childrenInHousehold: {
		1: 'None',
		2: 'One',
		3: 'Two',
		4: 'Three',
		5: 'Four',
		6: 'Five or more',
		7: 'DK/Refused',
	},
	countries: [
		
		{ country: 'Vietnam', region: 'South-eastern Asia' },
		{
			country: 'Norway',
			region: 'Northern/Western Europe',
		},
		{ country: 'Switzerland', region: 'Northern/Western Europe' },
		{
			country: 'Sweden',
			region: 'Northern/Western Europe',
		},
		{ country: 'Singapore', region: 'South-eastern Asia' },
		{
			country: 'United Arab Emirates',
			region: 'Middle East',
		},
		{ country: 'Philippines', region: 'South-eastern Asia' },
		{
			country: 'Finland',
			region: 'Northern/Western Europe',
		},
		{ country: 'Malaysia', region: 'South-eastern Asia' },
		{
			country: 'Austria',
			region: 'Northern/Western Europe',
		},
		{ country: 'Denmark', region: 'Northern/Western Europe' },
		{
			country: 'Netherlands',
			region: 'Northern/Western Europe',
		},
		{ country: 'Germany', region: 'Northern/Western Europe' },
		{
			country: 'New Zealand',
			region: 'Australia and New Zealand',
		},
		{ country: 'Canada', region: 'Northern America' },
		{
			country: 'United States',
			region: 'Northern America',
		},
		{ country: 'Australia', region: 'Australia and New Zealand' },
		{
			country: 'Estonia',
			region: 'Northern/Western Europe',
		},
		{ country: 'Taiwan', region: 'East Asia' },
		{
			country: 'Uzbekistan',
			region: 'Central Asia',
		},
		{ country: 'Iceland', region: 'Northern/Western Europe' },
		{
			country: 'Indonesia',
			region: 'South-eastern Asia',
		},
		{ country: 'Ireland', region: 'Northern/Western Europe' },
		{
			country: 'Tajikistan',
			region: 'Central Asia',
		},
		{ country: 'Slovenia', region: 'Southern Europe' },
		{
			country: 'Czech Republic',
			region: 'Eastern Europe',
		},
		{ country: 'Mauritius', region: 'Eastern Africa' },
		{
			country: 'United Kingdom',
			region: 'Northern/Western Europe',
		},
		{ country: 'Japan', region: 'East Asia' },
		{
			country: 'Croatia',
			region: 'Southern Europe',
		},
		{ country: 'Belgium', region: 'Northern/Western Europe' },
		{
			country: 'Algeria',
			region: 'North Africa',
		},
		{ country: 'Jordan', region: 'Middle East' },
		{
			country: 'Portugal',
			region: 'Southern Europe',
		},
		{ country: 'Thailand', region: 'South-eastern Asia' },
		{
			country: 'Greece',
			region: 'Southern Europe',
		},
		{ country: 'Lithuania', region: 'Northern/Western Europe' },
		{
			country: 'Slovakia',
			region: 'Eastern Europe',
		},
		{ country: 'France', region: 'Northern/Western Europe' },
		{
			country: 'South Korea',
			region: 'East Asia',
		},
		{ country: 'Malta', region: 'Southern Europe' },
		{
			country: 'Laos',
			region: 'South-eastern Asia',
		},
		{ country: 'Cyprus', region: 'Southern Europe' },
		{
			country: 'Hungary',
			region: 'Eastern Europe',
		},
		{ country: 'Italy', region: 'Southern Europe' },
		{
			country: 'Latvia',
			region: 'Northern/Western Europe',
		},
		{ country: 'Spain', region: 'Southern Europe' },
		{
			country: 'Ukraine',
			region: 'Eastern Europe',
		},
		{ country: 'Poland', region: 'Eastern Europe' },
		{
			country: 'Serbia',
			region: 'Southern Europe',
		},
		{ country: 'Cambodia', region: 'South-eastern Asia' },
		{
			country: 'Iran',
			region: 'Middle East',
		},
		{ country: 'Bangladesh', region: 'South Asia' },
		{
			country: 'Sri Lanka',
			region: 'South Asia',
		},
		{ country: 'Bulgaria', region: 'Eastern Europe' },
		{
			country: 'Hong Kong',
			region: 'East Asia',
		},
		{ country: 'Russia', region: 'Eastern Europe' },
		{
			country: 'Armenia',
			region: 'Central Asia',
		},
		{ country: 'Kazakhstan', region: 'Central Asia' },
		{
			country: 'Dominican Republic',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Mozambique', region: 'Eastern Africa' },
		{
			country: 'Nicaragua',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Jamaica', region: 'Latin America & Caribbean' },
		{
			country: 'Myanmar',
			region: 'South-eastern Asia',
		},
		{ country: 'Mexico', region: 'Latin America & Caribbean' },
		{
			country: 'Costa Rica',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Israel', region: 'Middle East' },
		{
			country: 'Bosnia Herzegovina',
			region: 'Southern Europe',
		},
		{ country: 'Kosovo', region: 'Eastern Europe' },
		{
			country: 'Romania',
			region: 'Eastern Europe',
		},
		{ country: 'Georgia', region: 'Central Asia' },
		{
			country: 'Tanzania',
			region: 'Eastern Africa',
		},
		{ country: 'Uruguay', region: 'Latin America & Caribbean' },
		{
			country: 'El Salvador',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Nepal', region: 'South Asia' },
		{
			country: 'Namibia',
			region: 'Southern Africa',
		},
		{ country: 'North Macedonia', region: 'Southern Europe' },
		{
			country: 'Turkey',
			region: 'Middle East',
		},
		{ country: 'India', region: 'South Asia' },
		{
			country: 'Argentina',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Ecuador', region: 'Latin America & Caribbean' },
		{
			country: 'Moldova',
			region: 'Eastern Europe',
		},
		{ country: 'Brazil', region: 'Latin America & Caribbean' },
		{
			country: 'South Africa',
			region: 'Southern Africa',
		},
		{ country: 'Kyrgyzstan', region: 'Central Asia' },
		{
			country: 'Morocco',
			region: 'North Africa',
		},
		{ country: 'Kenya', region: 'Eastern Africa' },
		{
			country: 'Chile',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Egypt', region: 'North Africa' },
		{
			country: 'Senegal',
			region: 'Central/Western Africa',
		},
		{ country: 'Bolivia', region: 'Latin America & Caribbean' },
		{
			country: 'Iraq',
			region: 'Middle East',
		},
		{ country: 'Mongolia', region: 'East Asia' },
		{
			country: 'Panama',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Peru', region: 'Latin America & Caribbean' },
		{
			country: 'Colombia',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Zambia', region: 'Eastern Africa' },
		{
			country: 'Benin',
			region: 'Central/Western Africa',
		},
		{ country: 'Ghana', region: 'Central/Western Africa' },
		{
			country: 'Cameroon',
			region: 'Central/Western Africa',
		},
		{ country: 'Paraguay', region: 'Latin America & Caribbean' },
		{
			country: 'Uganda',
			region: 'Eastern Africa',
		},
		{ country: 'Nigeria', region: 'Central/Western Africa' },
		{
			country: 'Sierra Leone',
			region: 'Central/Western Africa',
		},
		{ country: 'Zimbabwe', region: 'Eastern Africa' },
		{
			country: 'Honduras',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Tunisia', region: 'North Africa' },
		{
			country: 'Congo Brazzaville',
			region: 'Central/Western Africa',
		},
		{ country: 'Mali', region: 'Central/Western Africa' },
		{
			country: 'Albania',
			region: 'Southern Europe',
		},
		{ country: 'Guinea', region: 'Central/Western Africa' },
		{
			country: 'Venezuela',
			region: 'Latin America & Caribbean',
		},
		{ country: 'Burkina Faso', region: 'Central/Western Africa' },
		{
			country: 'Ivory Coast',
			region: 'Central/Western Africa',
		},
		{ country: 'Pakistan', region: 'South Asia' },
		{
			country: 'Gabon',
			region: 'Central/Western Africa',
		},
		{ country: 'Lebanon', region: 'Middle East' },
		{
			country: 'Togo',
			region: 'Central/Western Africa',
		},
		{ country: 'Afghanistan', region: 'South Asia' },
	],
	colorBy: {
		1: 'Resilience index',
		// 2: 'Global region',
		2: 'Country income level',
	},
};
