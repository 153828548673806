import { Box, InputLabel, Slider, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

const Range = ({ label, value, setValue, ...props }) => {
	const [intermediateValue, setIntermediateValue] = useState(value);

	useEffect(() => {
		setIntermediateValue(value);
	}, [value]);

	const handleChange = (event, newValue) => {
		setIntermediateValue(newValue);
	};

	const handleChangeCommitted = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Stack spacing={0}>
			<InputLabel>{label}</InputLabel>
			<Box>
				<Slider
					size={'medium'}
					orientation={'horizontal'}
					valueLabelDisplay={'off'}
					value={intermediateValue}
					onChangeCommitted={handleChangeCommitted}
					onChange={handleChange}
					step={1}
					marks={[
						{
							value: 1,
							label: 'Not important',
						},
						{
							value: 100,
							label: 'Very important',
						},
					]}
					sx={{
						'& .MuiSlider-markLabel': {
							transform: 'unset',
							color: 'text.secondary',
							'&[data-index="1"]': {
								transform: 'translateX(-100%)',
							},
						},
					}}
					min={1}
					max={100}
					{...props}
				/>
			</Box>
		</Stack>
	);
};

export default Range;
