import { Badge, IconButton, Stack, ToggleButton, Tooltip } from '@mui/material';
import { StyledToggleButtonGroup } from './controlViewSwitch.styles';
import { useFilterContext, useMenuContext, useSelectionContext, useWeightContext } from '../../context';
import { controlViews } from '../../context/MenuContext';
import * as React from 'react';

const ControlViewSwitch = () => {
	const { selectedMenuItem, setSelectedMenuItem } = useMenuContext();
	const { isMenuOpen, setMenuOpen } = useMenuContext();
	const { isEmpty: isFilterEmpty } = useFilterContext();
	const { isEmpty: isWeightEmpty } = useWeightContext();
	const { isEmpty: isSelectionEmpty } = useSelectionContext();
	const iconSize = 24;

	const handleChange = (event, value) => {
		if (value) {
			setSelectedMenuItem(value);
		}
	};

	return (
		<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={'20px'}>
			<Tooltip title={!isMenuOpen ? 'Show filters' : 'Hide filters'} placement="bottom" arrow>
				<IconButton
					color="primary"
					size={'large'}
					onClick={() => setMenuOpen(!isMenuOpen)}
					aria-selected={(!isMenuOpen).toString()}
				>
					<span className="material-symbols-outlined" style={{ fontSize: iconSize }}>
						{!isMenuOpen ? 'chevron_left' : 'chevron_right'}
					</span>
				</IconButton>
			</Tooltip>
			<StyledToggleButtonGroup color="primary" value={selectedMenuItem} exclusive onChange={handleChange}>
				<ToggleButton value={controlViews[0]} selected={!isMenuOpen ? false : undefined}>
					<Badge variant="dot" invisible={isSelectionEmpty} color="primary">
						<span className="material-symbols-outlined" style={{ fontSize: iconSize }}>
							public
						</span>
					</Badge>
				</ToggleButton>
				<ToggleButton value={controlViews[1]} selected={!isMenuOpen ? false : undefined}>
					<Badge variant="dot" invisible={isFilterEmpty} color="primary">
						<span className="material-symbols-outlined" style={{ fontSize: iconSize }}>
							filter_list
						</span>
					</Badge>
				</ToggleButton>
				<ToggleButton value={controlViews[2]} selected={!isMenuOpen ? false : undefined}>
					<Badge variant="dot" invisible={isWeightEmpty} color="primary">
						<span className="material-symbols-outlined" style={{ fontSize: iconSize }}>
							tune
						</span>
					</Badge>
				</ToggleButton>
			</StyledToggleButtonGroup>
		</Stack>
	);
};

export default ControlViewSwitch;
