import { Button, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import RangeWithChips from '../controls/RangeWithChips';
import Autocomplete from '../controls/Autocomplete';
import { lloydConstants } from '../../Constants';
import { useFilterContext } from '../../context';
import { defaultFilterValues } from '../../context/FilterContext';
import { getOptionsFromObject } from '../../utils';

const FiltersView = () => {
	const theme = useTheme();
	const filters = useFilterContext();

	const resetAll = () => {
		filters.setUrbanicity(defaultFilterValues.urbanicity);
		filters.setIncome(defaultFilterValues.income);
		filters.setHouseholdSize(defaultFilterValues.householdSize);
		filters.setGlobalRegion(defaultFilterValues.globalRegion);
		filters.setChildrenInHousehold(defaultFilterValues.childrenInHousehold);
		filters.setGender(defaultFilterValues.gender);
		filters.setEmploymentStatus(defaultFilterValues.employmentStatus);
		filters.setEducationLevel(defaultFilterValues.educationLevel);
		filters.setAgeGroup(defaultFilterValues.ageGroup);
	};

	const getChipsFromObject = (obj = {}) => {
		const options = [];

		for (const [key, value] of Object.entries(obj)) {
			options.push({
				id: key,
				label: value.label,
				value: value.value,
			});
		}

		return options;
	};

	return (
		<Stack spacing={'20px'} width={'100%'}>
			<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
				<Stack spacing={1} direction={'row'} alignItems={'center'}>
					<Typography variant={'h5'}>Filters</Typography>
					<Tooltip
						title="Filter the Resilience Index by demographic information. Filters are additive and will cause the chart to re-scale and re-order. Countries will disappear from the index when filtering becomes too stringent."
						placement="bottom"
						arrow
					>
						<span className="material-symbols-outlined" style={{ fontSize: 24, color: theme.palette.primary.main }}>
							help
						</span>
					</Tooltip>
				</Stack>
				<Button
					variant={'outlined'}
					size={'medium'}
					onClick={resetAll}
					sx={{ visibility: filters.isEmpty ? 'hidden' : 'visible' }}
				>
					Reset all
				</Button>
			</Stack>
			<Stack spacing={'20px'}>
				<Autocomplete
					options={getOptionsFromObject(lloydConstants.genders)}
					label={'Gender'}
					value={filters.gender}
					setValue={filters.setGender}
				/>
				<RangeWithChips
					label={'Age group'}
					value={filters.ageGroup}
					setValue={filters.setAgeGroup}
					chips={getChipsFromObject(lloydConstants.ageGroups)}
				/>
				<Autocomplete
					options={getOptionsFromObject(lloydConstants.educationLevels)}
					label={'Education level'}
					value={filters.educationLevel}
					setValue={filters.setEducationLevel}
				/>
				<Autocomplete
					options={getOptionsFromObject(lloydConstants.incomeQuintiles)}
					label={'Income'}
					value={filters.income}
					setValue={filters.setIncome}
				/>
				<Autocomplete
					options={getOptionsFromObject(lloydConstants.employmentStatus)}
					label={'Employment status'}
					value={filters.employmentStatus}
					setValue={filters.setEmploymentStatus}
				/>
				<Autocomplete
					options={getOptionsFromObject(lloydConstants.urbanicity)}
					label={'Urbanicity'}
					value={filters.urbanicity}
					setValue={filters.setUrbanicity}
				/>
				<Autocomplete
					options={getOptionsFromObject(lloydConstants.householdSize)}
					label={'Household size'}
					value={filters.householdSize}
					setValue={filters.setHouseholdSize}
				/>
				<Autocomplete
					options={getOptionsFromObject(lloydConstants.childrenInHousehold)}
					label={'Children in household'}
					value={filters.childrenInHousehold}
					setValue={filters.setChildrenInHousehold}
				/>
			</Stack>
		</Stack>
	);
};

export default FiltersView;
