import { InputLabel, MenuItem, Select as MUISelect, Stack } from '@mui/material';

const Select = ({ label, setValue, options, ...props }) => {
	return (
		<Stack spacing={1}>
			<InputLabel>{label}</InputLabel>
			<MUISelect
				size={'medium'}
				onChange={(event) => {
					setValue(event.target.value);
				}}
				{...props}
			>
				{options.map((option) => (
					<MenuItem key={option.id} value={option.label}>
						{option.label}
					</MenuItem>
				))}
			</MUISelect>
		</Stack>
	);
};

export default Select;
