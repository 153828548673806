import { Button, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useSelectionContext, useYearContext } from '../../context';
import Autocomplete from '../controls/Autocomplete';
import { lloydConstants } from '../../Constants';
import { getOptionsFromObject } from '../../utils';
import { defaultSelectionValues } from '../../context/SelectionContext';
import Select from '../controls/Select';

const SelectionView = () => {
	const theme = useTheme();
	const selection = useSelectionContext();
	const { selectedYear } = useYearContext();

	const resetAll = () => {
		selection.setCountry(defaultSelectionValues.country);
		selection.setIncomeLevel(defaultSelectionValues.incomeLevel);
		selection.setColorBy(defaultSelectionValues.colorBy);
	};

	const getGroupedCountryOptions = () => {
		const options = [];

		for (const [k, v] of Object.entries(lloydConstants.countriesProps)) {
			let add = true;
			if ((selectedYear === 2021) & ['Somalia', 'Niger', 'Congo (Kinshasa)'].includes(k)) {
				add = false;
			}

			if (add) {
				options.push({
					id: k,
					label: k,
					group: v.region,
				});
			}
		}

		return options;
	};

	return (
		<Stack spacing={'20px'} width={'100%'}>
			<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
				<Stack spacing={1} direction={'row'} alignItems={'center'}>
					<Typography variant={'h5'}>Highlights</Typography>
					<Tooltip
						title="Select individual or multiple countries, global regions or World Bank country income groupings."
						placement="bottom"
						arrow
					>
						<span className="material-symbols-outlined" style={{ fontSize: 24, color: theme.palette.primary.main }}>
							help
						</span>
					</Tooltip>
				</Stack>
				<Button
					variant={'outlined'}
					size={'medium'}
					onClick={resetAll}
					sx={{ visibility: selection.isEmpty ? 'hidden' : 'visible' }}
				>
					Reset all
				</Button>
			</Stack>
			<Stack spacing={'20px'}>
				<Autocomplete
					options={getGroupedCountryOptions()}
					label={'Country'}
					value={selection.country}
					setValue={selection.setCountry}
					isGroup={true}
				/>
				<Autocomplete
					options={getOptionsFromObject(lloydConstants.incomeLevels)}
					label={'Country income level'}
					value={selection.incomeLevel}
					setValue={selection.setIncomeLevel}
				/>
				<Select
					options={getOptionsFromObject(lloydConstants.colorBy)}
					label={'Colour countries by'}
					value={selection.colorBy}
					setValue={selection.setColorBy}
				/>
			</Stack>
		</Stack>
	);
};

export default SelectionView;
