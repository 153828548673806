import { IconButton, Tooltip } from '@mui/material';
import * as React from 'react';

const DownloadButton = () => {
	const anchorRef = React.useRef(null);

	const downloadOriginalData = () => {
		window.location.href = './data/wrp_all_data.zip';
	};

	const onClick = () => {
		downloadOriginalData();
	};

	return (
		<>
			<Tooltip title="Download" placement="bottom" arrow>
				<span>
					<IconButton color="primary" size={'large'} onClick={onClick} ref={anchorRef}>
						<span className="material-symbols-outlined" style={{ fontSize: 24 }}>
							download
						</span>
					</IconButton>
				</span>
			</Tooltip>
		</>
	);
};

export default DownloadButton;
