import { Button, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import Range from '../controls/Range';
import { useWeightContext } from '../../context';
import { defaultWeightValues } from '../../context/WeightContext';

const WeightsView = () => {
	const theme = useTheme();
	const weights = useWeightContext();

	const resetAll = () => {
		weights.setCommunity(defaultWeightValues.community);
		weights.setHousehold(defaultWeightValues.household);
		weights.setIndividual(defaultWeightValues.individual);
		weights.setSociety(defaultWeightValues.society);
	};

	return (
		<Stack spacing={'20px'} width={'100%'}>
			<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
				<Stack spacing={1} direction={'row'} alignItems={'center'}>
					<Typography variant={'h5'}>Weights</Typography>
					<Tooltip
						title="Adjust the weighting of the four individual domains. The chart will re-scale and re-order based on your selections."
						placement="bottom"
						arrow
					>
						<span className="material-symbols-outlined" style={{ fontSize: 24, color: theme.palette.primary.main }}>
							help
						</span>
					</Tooltip>
				</Stack>
				<Button
					variant={'outlined'}
					size={'medium'}
					onClick={resetAll}
					sx={{ visibility: weights.isEmpty ? 'hidden' : 'visible' }}
				>
					Reset all
				</Button>
			</Stack>
			<Stack spacing={'20px'}>
				<Range label={'Community'} value={weights.community} setValue={weights.setCommunity} />
				<Range label={'Household'} value={weights.household} setValue={weights.setHousehold} />
				<Range label={'Society'} value={weights.society} setValue={weights.setSociety} />
				<Range label={'Individual'} value={weights.individual} setValue={weights.setIndividual} />
			</Stack>
		</Stack>
	);
};

export default WeightsView;
