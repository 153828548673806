export const getOptionsFromObject = (obj = {}) => {
	const options = [];

	for (const [key, value] of Object.entries(obj)) {
		options.push({
			id: key,
			label: value,
		});
	}

	return options;
};
