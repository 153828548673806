import { Stack, Typography } from '@mui/material';
import { useSelectionContext } from '../../context';
import { categoricalScale } from '../../RiskIndexViz';
import { lloydConstants } from '../../Constants';

const CountryIncomeLegend = () => {
	const { colorBy } = useSelectionContext();

	const renderLegendItem = (label, color) => {
		const colorSize = 16;

		return (
			<Stack direction={'row'} alignItems={'center'} spacing={1} key={label}>
				<svg width={colorSize + 2} height={colorSize + 2} fill={color}>
					<circle cx={colorSize / 2 + 1} cy={colorSize / 2 + 1} r={colorSize / 2} stroke={'black'} />
				</svg>
				<Typography variant={'body2'} fontWeight={500}>
					{label}
				</Typography>
			</Stack>
		);
	};

	const renderLegendItems = () => {
		const items = [];

		for (const [key, value] of Object.entries(lloydConstants.incomeLevels)) {
			let color = categoricalScale((key - 1) / 4);
			if (key === '9') color = '#D7D7D7';

			items.push(renderLegendItem(value, color));
		}

		return items;
	};

	return (
		<Stack
			spacing={'60px'}
			sx={{
				position: 'absolute',
				bottom: 20,
				left: 20,
				display: colorBy !== 'Country income level' ? 'none' : undefined,
			}}
			direction={'row'}
			alignItems={'center'}
		>
			<Typography fontWeight={500}>Country income levels</Typography>
			<Stack spacing={3} alignItems={'center'} direction={'row'} flexShrink={0}>
				{renderLegendItems()}
			</Stack>
		</Stack>
	);
};

export default CountryIncomeLegend;
