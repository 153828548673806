import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Chart from './Chart';
import ControlsView from './components/ControlsView';
import { useMenuContext } from './context';
import ControlViewSwitch from './components/ControlViewSwitch';
import { useTheme } from '@mui/material';
import CountryIncomeLegend from './components/CountryIncomeLegend';
import ResilienceIndexLegend from './components/ResilienceIndexLegend';
import ChartButtons from './components/ChartButtons';
import YearToggle from './components/YearToggle';
import CountryTooltip from './components/CountryTooltip';

const controlsDrawerWidth = 360 + 20 * 2;

const ChartWrapper = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	flexGrow: 1,
	position: 'relative',
	padding: 20,
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.shortest,
	}),
	marginRight: -controlsDrawerWidth,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.shorter,
		}),
		marginRight: 0,
	}),
}));

const MenuWrapper = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	marginRight: -controlsDrawerWidth + theme.menu.margin + theme.menu.size,
	transition: theme.transitions.create(['margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.shortest,
	}),
	...(open && {
		marginRight: 0,
		transition: theme.transitions.create(['margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.shorter,
		}),
	}),
}));

export default function App() {
	const { isMenuOpen } = useMenuContext();
	const theme = useTheme();

	return (
		<Box height={'100vh'} overflow={'hidden'}>
			<Box width={'100%'} justifyContent={'flex-end'} display={'flex'}>
				<MenuWrapper open={isMenuOpen} width={controlsDrawerWidth}>
					<ControlViewSwitch />
				</MenuWrapper>
			</Box>
			<Box sx={{ display: 'flex' }} height={`calc(100% - ${theme.menu.size}px)`}>
				<ChartWrapper open={isMenuOpen}>
					<Chart />
					<YearToggle />
					<CountryIncomeLegend />
					<ResilienceIndexLegend />
					<ChartButtons />
				</ChartWrapper>
				<CountryTooltip />
				<Drawer
					sx={{
						width: controlsDrawerWidth,
						flexShrink: 0,
						'& .MuiDrawer-paper': {
							width: controlsDrawerWidth,
							border: 'none',
							paddingTop: theme.menu.size + 'px',
							height: `calc(100% - ${theme.menu.size}px)`,
						},
					}}
					variant="persistent"
					anchor="right"
					open={isMenuOpen}
				>
					<ControlsView />
				</Drawer>
			</Box>
		</Box>
	);
}
