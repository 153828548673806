import { Stack, useTheme } from '@mui/material';
import ExplanationTooltip from '../ExplanationTooltip';
import DownloadButton from '../DownloadButton';
import * as React from 'react';
import { useMenuContext } from '../../context';

const ChartButtons = () => {
	const theme = useTheme();
	const { isMenuOpen } = useMenuContext();

	return (
		<Stack
			spacing={'5px'}
			direction={'row'}
			sx={{ position: 'absolute', right: isMenuOpen ? 20 : 114, top: `${20 - theme.menu.size}px` }}
		>
			<ExplanationTooltip />
			<DownloadButton />
		</Stack>
	);
};

export default ChartButtons;
