import { createContext, useContext, useMemo, useState } from 'react';

export const defaultFilterValues = {
	globalRegion: [],
	setGlobalRegion: () => {},
	gender: [],
	setGender: () => {},
	ageGroup: [15, 100],
	setAgeGroup: () => {},
	educationLevel: [],
	setEducationLevel: () => {},
	income: [],
	setIncome: () => {},
	employmentStatus: [],
	setEmploymentStatus: () => {},
	urbanicity: [],
	setUrbanicity: () => {},
	householdSize: [],
	setHouseholdSize: () => {},
	childrenInHousehold: [],
	setChildrenInHousehold: () => {},
	isEmpty: true,
};

const FilterContext = createContext(defaultFilterValues);

export const FilterProvider = ({ children }) => {
	const [globalRegion, setGlobalRegion] = useState(defaultFilterValues.globalRegion);
	const [gender, setGender] = useState(defaultFilterValues.gender);
	const [ageGroup, setAgeGroup] = useState(defaultFilterValues.ageGroup);
	const [educationLevel, setEducationLevel] = useState(defaultFilterValues.educationLevel);
	const [income, setIncome] = useState(defaultFilterValues.income);
	const [employmentStatus, setEmploymentStatus] = useState(defaultFilterValues.employmentStatus);
	const [urbanicity, setUrbanicity] = useState(defaultFilterValues.urbanicity);
	const [householdSize, setHouseholdSize] = useState(defaultFilterValues.householdSize);
	const [childrenInHousehold, setChildrenInHousehold] = useState(defaultFilterValues.childrenInHousehold);

	const isEmpty = useMemo(() => {
		let isEmpty = true;

		for (const value of [
			globalRegion,
			gender,
			ageGroup,
			educationLevel,
			income,
			employmentStatus,
			urbanicity,
			householdSize,
			childrenInHousehold,
		]) {
			if (
				value.length > 0 &&
				(value[0] !== defaultFilterValues.ageGroup[0] || value[1] !== defaultFilterValues.ageGroup[1])
			) {
				isEmpty = false;
				break;
			}
		}

		return isEmpty;
	}, [
		globalRegion,
		gender,
		ageGroup,
		educationLevel,
		income,
		employmentStatus,
		urbanicity,
		householdSize,
		childrenInHousehold,
	]);

	return (
		<FilterContext.Provider
			value={{
				globalRegion,
				setGlobalRegion,
				gender,
				setGender,
				ageGroup,
				setAgeGroup,
				educationLevel,
				setEducationLevel,
				income,
				setIncome,
				employmentStatus,
				setEmploymentStatus,
				urbanicity,
				setUrbanicity,
				householdSize,
				setHouseholdSize,
				childrenInHousehold,
				setChildrenInHousehold,
				isEmpty,
			}}
		>
			{children}
		</FilterContext.Provider>
	);
};

export const useFilterContext = () => useContext(FilterContext);
