import * as d3 from 'd3';
import { useState, useEffect } from 'react';
import { lloydConstants } from './Constants';
import { useYearContext } from './context/YearContext';

const processRow = (d) => {
	d = d3.autoType(d);

	const countryCode = d.country_code;
	const countryName = lloydConstants.countryCodeToName[countryCode];
	const countryProps = lloydConstants.countriesProps[countryName];

	//get the country structure from Constant
	d.Country = countryName;

	if (countryProps) {
		d.GlobalRegion = countryProps.region;
		// d.CountryIncomeLevel2021 = countryProps.country_income;
		d.CountryIncomeLevel2021 = lloydConstants.reverseIncomeLevels[countryProps.country_income];
	}

	//check if d.Household is null or undefined, and for other props
	if (
		d.Household === null ||
		d.Household === undefined ||
		d.Individual === null ||
		d.Individual === undefined ||
		d.Community === null ||
		d.Community === undefined ||
		d.Society === null ||
		d.Society === undefined ||
		d.resilience_index === null ||
		d.resilience_index === undefined
	) {
		return null;
	}

	return d;
};

export const useData = () => {
	// const [data, setData] = useState({});
	const { setYearlyData2021, setYearlyData2023 } = useYearContext();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// const promises = [d3.csv('data/lrf_wrp_2021.csv', d3.autoType)];
		const promises = [
			d3.csv('data/transformed_data_2021.csv', processRow),
			d3.csv('data/transformed_data_2023.csv', processRow),
		];

		Promise.all(promises).then((data) => {
			setYearlyData2021(data[0].filter((d) => d !== null));
			setYearlyData2023(data[1].filter((d) => d !== null));

			setLoading(false);
		});

		console.debug('useData.js; fetched ', new Date().getMilliseconds());

		return () => undefined;
	}, [setYearlyData2021, setYearlyData2023]);

	return {
		// data,
		loading,
	};
};
