import './App.css';
import RiskIndexViz from './RiskIndexViz';
import { useData } from './useData';
import * as React from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

function Chart() {
	const { loading } = useData();

	return (
		<div className="container">
			<Box alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'} display={'flex'}>
				{loading && (
					<Stack spacing={2} alignItems={'center'}>
						<CircularProgress />
						<Typography>Loading data, please wait...</Typography>
					</Stack>
				)}
				{!loading && (
					<div className="content-map">
						<RiskIndexViz />
					</div>
				)}
			</Box>
		</div>
	);
}

export default Chart;
