import { createContext, useContext, useState } from 'react';

export const controlViews = ['Selection', 'Filters', 'Weights'];

const defaultMenuValues = {
	isMenuOpen: true,
	setMenuOpen: () => {},
	selectedMenuItem: controlViews[0],
	setSelectedMenuItem: () => {},
};

const MenuContext = createContext(defaultMenuValues);

export const MenuProvider = ({ children }) => {
	const [isMenuOpen, setMenuOpen] = useState(defaultMenuValues.isMenuOpen);
	const [selectedMenuItem, setSelectedMenuItem] = useState(defaultMenuValues.selectedMenuItem);

	return (
		<MenuContext.Provider
			value={{
				isMenuOpen,
				setMenuOpen,
				selectedMenuItem,
				setSelectedMenuItem,
			}}
		>
			{children}
		</MenuContext.Provider>
	);
};

export const useMenuContext = () => useContext(MenuContext);
