import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ThemeProvider } from '@mui/material';
import App from './App';
import { customTheme } from './theme';
import { FilterProvider, MenuProvider, SelectionProvider, WeightProvider, YearProvider } from './context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<ThemeProvider theme={customTheme}>
		<MenuProvider>
			<YearProvider>
				<FilterProvider>
					<WeightProvider>
						<SelectionProvider>
							<App />
						</SelectionProvider>
					</WeightProvider>
				</FilterProvider>
			</YearProvider>
		</MenuProvider>
	</ThemeProvider>
);
