import { createContext, useContext, useMemo, useState } from 'react';
import { lloydConstants } from '../Constants';

export const defaultSelectionValues = {
	country: [],
	setCountry: () => {},
	incomeLevel: [],
	setIncomeLevel: () => {},
	colorBy: lloydConstants.colorBy['1'],
	setColorBy: () => {},
	isEmpty: true,
};

const SelectionContext = createContext(defaultSelectionValues);

export const SelectionProvider = ({ children }) => {
	const [country, setCountry] = useState(defaultSelectionValues.country);
	const [incomeLevel, setIncomeLevel] = useState(defaultSelectionValues.incomeLevel);
	const [colorBy, setColorBy] = useState(defaultSelectionValues.colorBy);

	const isEmpty = useMemo(() => {
		let isEmpty = true;
		for (const value of [country, incomeLevel, colorBy]) {
			if (Array.isArray(value)) {
				if (value.length > 0) {
					isEmpty = false;
					break;
				}
			}

			if ((typeof value === 'string' || value instanceof String) && value !== defaultSelectionValues.colorBy) {
				isEmpty = false;
				break;
			}
		}

		return isEmpty;
	}, [country, incomeLevel, colorBy]);

	return (
		<SelectionContext.Provider
			value={{
				country,
				setCountry,
				incomeLevel,
				setIncomeLevel,
				colorBy,
				setColorBy,
				isEmpty,
			}}
		>
			{children}
		</SelectionContext.Provider>
	);
};

export const useSelectionContext = () => useContext(SelectionContext);
