import { Box, Divider, Paper, Stack, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { useYearContext } from '../../context';

const CountryTooltip = () => {
	const colorSize = 10;
	const theme = useTheme();
	const { selectedYear } = useYearContext();

	const renderResilienceInfoItem = (className, iconName, label) => {
		return (
			<Stack className={`ri-tooltip-row ${className}`} direction={'row'}>
				<Stack direction={'row'} alignItems={'center'} spacing={1} flex={1}>
					<span className="material-symbols-outlined" style={{ fontSize: 20, color: theme.palette.primary.dark }}>
						{iconName}
					</span>
					<Typography className="ri-tooltip-key" variant={'body2'}>
						{label}
					</Typography>
				</Stack>
				<Stack direction={'row'} spacing={'12px'} flex={1} alignItems={'center'} marginLeft={'-11px'}>
					<Stack direction={'row'} alignItems={'center'} spacing={'4px'}>
						<svg width={colorSize + 2} height={colorSize + 2} fill="green" className="ri-tooltip-color-value">
							<circle cx={colorSize / 2 + 1} cy={colorSize / 2 + 1} r={colorSize / 2} stroke={'black'} />
						</svg>
						<Typography className="ri-tooltip-value" variant={'body2'}>
							74
						</Typography>
					</Stack>
					<Typography className="ri-tooltip-old-value" variant={'body2'} color={'primary.main'}>
						58 (-2.1%) todo
					</Typography>
				</Stack>
			</Stack>
		);
	};

	return (
		<Paper className="ri-tooltip" elevation={24}>
			<Stack spacing={'15px'}>
				<Stack spacing={'20px'}>
					<Typography variant={'h5'} className="ri-tooltip-title" color={'primary.main'}>
						Country name
					</Typography>

					<Stack spacing={'5px'}>
						<Stack className="ri-tooltip-row global-region" direction={'row'}>
							<Typography className="ri-tooltip-key" variant={'body2'} flex={1}>
								Global region
							</Typography>
							<Typography className="ri-tooltip-value" variant={'body2'} flex={1} fontWeight={500}>
								Middle Earth
							</Typography>
						</Stack>
						<Stack className="ri-tooltip-row income-group" justifyContent={'space-between'} direction={'row'}>
							<Typography className="ri-tooltip-key" variant={'body2'} flex={1}>
								Income level
							</Typography>
							<Typography className="ri-tooltip-value" variant={'body2'} flex={1} fontWeight={500}>
								Gigimil
							</Typography>
						</Stack>
						<Stack className="ri-tooltip-row total" justifyContent={'space-between'} direction={'row'}>
							<Typography className="ri-tooltip-key" variant={'body2'} flex={1}>
								Total respondents
							</Typography>
							<Typography className="ri-tooltip-value" variant={'body2'} flex={1} fontWeight={500}>
								Value
							</Typography>
						</Stack>
						<Stack className="ri-tooltip-row original-position" justifyContent={'space-between'} direction={'row'}>
							<Typography className="ri-tooltip-key" variant={'body2'} flex={1}>
								Original position in statistic
							</Typography>
							<Stack direction={'row'} spacing={'12px'} flex={1}>
								<Typography className="ri-tooltip-value" variant={'body2'} fontWeight={500}>
									152 / 200
								</Typography>
								<Typography className="ri-tooltip-old-value" variant={'body2'} color={'primary.main'}>
									150 / 200 (+2) todo
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
				<Divider />
				<Stack spacing={'15px'}>
					<Stack className="ri-tooltip-row filtered" direction={'row'}>
						<Typography className="ri-tooltip-key" flex={1}>
							Filtered respondents
						</Typography>
						<Typography className="ri-tooltip-value" fontWeight={500} flex={1}>
							Value
						</Typography>
					</Stack>
					<Stack spacing={'5px'}>
						<Stack className="ri-tooltip-row filtered-position" direction={'row'}>
							<Typography className="ri-tooltip-key" variant={'body2'} flex={1}>
								Position in statistic
							</Typography>
							<Stack direction={'row'} spacing={'12px'} flex={1}>
								<Typography className="ri-tooltip-value" variant={'body2'} fontWeight={500}>
									152 / 200
								</Typography>
								<Typography className="ri-tooltip-old-value" variant={'body2'} color={'primary.main'}>
									150 / 200 (+2) todo
								</Typography>
							</Stack>
						</Stack>
						<Stack className="ri-tooltip-row gender" direction={'row'} flex={1}>
							<Typography className="ri-tooltip-key" variant={'body2'} flex={1}>
								Female / Male
							</Typography>
							<Typography className="ri-tooltip-value" variant={'body2'} flex={1} fontWeight={500}>
								Value
							</Typography>
						</Stack>
					</Stack>
				</Stack>
				<Divider />
				<Stack spacing={'15px'}>
					<Stack className="ri-tooltip-row resilience" direction={'row'}>
						<Typography className="ri-tooltip-key" flex={1}>
							Resilience Index
						</Typography>
						<Stack direction={'row'} spacing={'12px'} flex={1}>
							<Typography className="ri-tooltip-value" fontWeight={500}>
								0.764
							</Typography>
							<Typography className="ri-tooltip-old-value" color={'primary.main'}>
								56 (-1.3%) todo
							</Typography>
						</Stack>
					</Stack>
					<Stack spacing={'5px'}>
						{renderResilienceInfoItem('household', 'home', 'Household')}
						{renderResilienceInfoItem('community', 'public', 'Community')}
						{renderResilienceInfoItem('society', 'group', 'Society')}
						{renderResilienceInfoItem('individual', 'person', 'Individual')}
					</Stack>
				</Stack>
				<Stack pt={'5px'} direction={'row'}>
					<Box flex={1} />
					<Box flex={1}>
						<Typography className="ri-tooltip-old-year" color={'primary.main'} variant={'body2'}>
							{`Compared with data from 2021}`}
						</Typography>
					</Box>
				</Stack>
			</Stack>
		</Paper>
	);
};

export default CountryTooltip;
